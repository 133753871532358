.centered-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.centered-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.centered-popup-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  padding: 24px;
  max-width: 250px;
  width: calc(100% - 32px);
  margin: 16px;
}

.centered-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.centered-popup-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.centered-popup-close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  transition: color 0.2s;
}

.centered-popup-close-button:hover {
  color: #374151;
}

.centered-popup-body {
  margin-top: 8px;
}


.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.login-title {
  font-size: 24px;
}


.login-button:hover {
  background-color: #0056b3;
}

.logo {
  width: 50%;
  display: block;
  margin: auto !important;
}

.login-message,
.register-redirect {
  font-size: 0.8rem;
  text-align: center;
}

.register-redirect .link {
  text-decoration: underline;
  cursor: pointer;
}

.button-signin {
  cursor: pointer;
  transition: background-color 0.3s;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 50px;
  padding-right: 50px;
  background: #f1c44f;
  color: #fff;
  margin-top: 16px;
  border-radius: 999px;
}

.button-signin:hover {
  background: #e5b73e;
}
