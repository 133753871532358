@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --app-container-width: 100%;
  --grid-row-gap: 10px;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  --primary-color: #E6B94B;
  --danger-color: #ff3333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}



.App {
  row-gap: var(--grid-row-gap);
  width: var(--app-container-width);
}

.Converter {
  display: grid;
  justify-content: center;
}


.Title {
  text-align: center;
}


/* html native */
h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Button {
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  width: fit-content;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.Button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent);
  transition: all 0.4s ease;
}

.Button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.Button:hover:before {
  left: 100%;
}

.Button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Button.Disabled {
  /* background-color: #cccccc; */
  opacity: 0.5;
  color: #666666;
  cursor: not-allowed;
  box-shadow: none;
}

.Button.Disabled:hover {
  transform: none;
}

.Button.Disabled:before {
  display: none;
}

.ButtonSecondary {
  padding: 10px 20px;
  background-color: #fff;
  color: var(--primary-color);
  font-size: 16px;
  width: fit-content;
  border-radius: 8px;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;
  border: 2px solid var(--primary-color);
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.ButtonSecondary:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(var(--primary-color-rgb), 0.3),
      transparent);
  transition: all 0.4s ease;
  z-index: 1;
}

.ButtonSecondary:hover {
  color: #fff;
  background-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.ButtonSecondary:hover:before {
  left: 100%;
}

.ButtonSecondary:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ButtonSecondary.Small {
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 6px;
  margin: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ButtonSecondary.Small:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.ButtonSecondary.Small:active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.Button.Small {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 6px;
  margin: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.Button.Small:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.Button.Small:active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}





/* 404 styling */
.NotFoundPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.WrapContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/*  Mobile  */

@media only screen and (max-width: 600px) {
  .App {
    row-gap: 5px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

}


@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
    transform: translateY(20px);
  }

  20%,
  80% {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature-content {
  animation: fadeInOut 3s infinite;
}
